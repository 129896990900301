import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  /* eslint-disable */
  us: require('../lang/us.json'),
  de: require('../lang/de.json'),
  ar: require('../lang/ar.json'),
  bg: require('../lang/bg.json'),
  fr: require('../lang/fr.json'),
  hu: require('../lang/hu.json'),
  ro: require('../lang/ro.json'),
  ru: require('../lang/ru.json'),
  al: require('../lang/al.json'),
};

export default new VueI18n({
  locale: 'de', // set locale
  messages, // set locale messages
  fallbackLocale: 'us',
});
