<template>
  <v-stepper v-model="e6" vertical style="max-width:600px;">
    <v-row no-gutters class="pr-4 pt-2">
      <type-switch />
      <v-spacer />
      <lang-switch />
    </v-row>
    <v-row no-gutters>
      <v-col>
        <img src="/hedwig.png" />
      </v-col>
      <v-col class="ml-4 headline" align-self="center">
        {{ $t('1') }}
      </v-col>
    </v-row>

    <p class="ma-4 d-print-none">
      {{ $t('2') }}
      <br />
      <br />
      {{ $t('3') }}
      <br />
      {{ $t('4') }}
      <br />
      <template v-if="$t('5')">
        {{ $t('5') }}
        <br />
        <br />
      </template>
      {{ $t('6') }}
      <br />

      <a
        href="https://www.barmherzige-hedwig.de/patienten-besucher/wissenswertes-von-a-z/besuchszeiten-und-nachtruhe.html"
        target="_blank"
        >{{ $t('7') }}</a
      >
      <br />
      <a
        href="https://www.barmherzige-hedwig.de/patienten-besucher/wissenswertes-von-a-z/hygieneregeln.html"
        >{{ $t('8') }}</a
      >
      <br />
    </p>

    <!-- step 1 -->
    <v-stepper-step :complete="e6 > 1" step="1" class="d-print-none">
      {{ $t('9') }}
    </v-stepper-step>
    <v-stepper-content step="1" class="d-print-none">
      <v-form v-model="valid.agreement">
        <v-row v-for="agreement in agreements" :key="agreement.id">
          <v-col cols="1" class="ml-3">
            <v-checkbox v-model="form.a[agreement.id]" :rules="[rules.required]" :value="1" />
          </v-col>
          <v-col>
            <p class="body-2">{{ $t(agreement.translation) }}</p>
          </v-col>
        </v-row>
        <p class="body-2" style="margin-left:58px;">
          <a :href="`/datenschutz_${$i18n.locale}.html`" target="_blank">{{ $t('12') }}</a>
        </p>
      </v-form>
      <v-btn color="primary" @click="e6 = 2" :disabled="!this.valid.agreement">
        <v-icon>mdi-check-circle-outline</v-icon>
        {{ $t('forward') }}
      </v-btn>
    </v-stepper-content>

    <!-- step 2 -->
    <v-stepper-step :complete="e6 > 2" step="2" class="d-print-none">
      {{ $t('14') }}
    </v-stepper-step>
    <v-stepper-content step="2" class="d-print-none">
      <v-form v-model="valid.general">
        <v-simple-table class="grey lighten-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="bool" style="margin-left:-8px;">{{ $t('18.yes') }}</th>
                <th class="bool" style="margin-left:-8px;">{{ $t('18.no') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="question in questions" :key="`q${question.id}`">
                <td style="color:black;">{{ $t(question.translation) }}</td>
                <td class="bool">
                  <v-checkbox v-model="form.q[question.id]" :value="1" :rules="[rules.required]" />
                </td>
                <td class="bool">
                  <v-checkbox v-model="form.q[question.id]" :value="2" :rules="[rules.required]" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>

      <div class="mt-4">
        <v-btn color="primary" @click="e6 = 3" :disabled="!this.valid.general">
          <v-icon>mdi-check-circle-outline</v-icon>
          {{ $t('forward') }}
        </v-btn>

        <v-btn text @click="e6 = 1">
          <v-icon>mdi-backspace-outline</v-icon>
          {{ $t('backward') }}
        </v-btn>
      </div>
    </v-stepper-content>

    <!-- step 3 -->
    <v-stepper-step :complete="e6 > 3" step="3" class="d-print-none">{{ $t('19') }}</v-stepper-step>

    <v-stepper-content step="3" class="d-print-none">
      <p class="text--black">{{ $t(20) }}</p>
      <v-form v-model="valid.symptoms">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="bool" style="margin-left:-8px;">{{ $t('18.yes') }}</th>
                <th class="bool" style="margin-left:-8px;">{{ $t('18.no') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="symptom in symptoms.values" :key="`s${symptom.id}`">
                <td class="text--black">{{ $t(symptom.translation) }}</td>

                <td class="bool">
                  <v-checkbox v-model="form.s[symptom.id]" :value="1" :rules="[rules.required]" />
                </td>
                <td class="bool">
                  <v-checkbox v-model="form.s[symptom.id]" :value="2" :rules="[rules.required]" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>

      <div class="mt-4">
        <v-btn color="primary" @click="e6 = 4" :disabled="!this.valid.symptoms">
          <v-icon>mdi-check-circle-outline</v-icon>
          {{ $t('forward') }}
        </v-btn>
        <v-btn text @click="e6 = 2">
          <v-icon>mdi-backspace-outline</v-icon>
          {{ $t('backward') }}
        </v-btn>
      </div>
    </v-stepper-content>

    <v-stepper-step :complete="e6 > 4" step="4" class="d-print-none">{{ $t('31') }}</v-stepper-step>

    <v-stepper-content step="4" class="d-print-none">
      <v-card color="grey lighten-5" class="ma-2" height="200px" flat>
        <v-form v-model="valid.contact">
          <v-text-field
            :label="$t('32.first')"
            outlined
            dense
            v-model="form.f"
            :rules="[rules.required]"
          />
          <v-text-field
            :label="$t('32.second')"
            outlined
            dense
            v-model="form.l"
            :rules="[rules.required]"
          />

          <vue-tel-input-vuetify
            @input="(value, tel) => handleNumber(tel)"
            :value="number"
            defaultCountry="de"
            disabledFetchingCountry
            :placeholder="$t('33')"
            label
            :error-messages="invalidNumber ? 'Ungültige Nummer' : ''"
          ></vue-tel-input-vuetify>
          <v-text-field v-model="form.n" :rules="[rules.required]" style="display:none" />
        </v-form>
      </v-card>

      <div class="mt-4">
        <v-btn color="primary" @click="generate" :disabled="!this.valid.contact">
          <v-icon>mdi-check-circle-outline</v-icon>
          {{ $t('forward') }}
        </v-btn>
        <v-btn text @click="e6 = 3">
          <v-icon>mdi-backspace-outline</v-icon>
          {{ $t('backward') }}
        </v-btn>
      </div>
    </v-stepper-content>

    <v-stepper-step step="5" class="d-print-none">{{ $t('34') }}</v-stepper-step>
    <v-stepper-content step="5">
      <p>{{ $t('show.code') }}</p>

      <canvas class="mt-4" id="code" style="weight: 250px; height:250px" />
      <br />
      <br />
      <div class="d-print-none">
        <v-btn @click="print">{{ $t('print') }}</v-btn>
        <v-btn text @click="e6 = 4">
          <v-icon>mdi-backspace-outline</v-icon>
          {{ $t('backward') }}
        </v-btn>
      </div>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import LangSwitch from '../components/LangSwitch.vue';
import TypeSwitch from '../components/TypeSwitch.vue';
const bwipjs = require('bwip-js');

async function createCode(text) {
  return new Promise((resolve, reject) => {
    bwipjs.toCanvas(
      'code',
      {
        bcid: 'datamatrix', // Barcode type
        text,
        width: 250,
        height: 250,
        scale: 1,
      },
      (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      },
    );
  });
}

export default {
  name: 'App',

  components: { LangSwitch, TypeSwitch },

  data: (vm) => ({
    e6: 1,
    number: '',
    valid: {
      general: false,
      symptoms: false,
      contact: false,
      agreement: false,
    },
    rules: {
      required: (v) => !!v || '',
      minLength: (v) => v.length >= 8 || `Muss mindestens 8 Zeichen lang sein`,
    },
    questions: [
      {
        id: 0,
        text: '',
        translation: 15,
      },
      {
        id: 1,
        text: '',
        translation: 16,
      },
      {
        id: 2,
        text: '',
        translation: 17,
      },
    ],
    symptoms: {
      text:
        'Sind bei Ihnen in den letzten 14 Tagen eins oder mehrere der folgenden Symptome aufgetreten? ',
      values: [
        { id: 0, text: 'Fieber (Temperatur ≥ 38,0° C)', translation: 21 },
        { id: 1, text: 'Schnupfen', translation: 22 },
        { id: 2, text: 'Husten', translation: 23 },
        { id: 3, text: 'Halsschmerzen', translation: 24 },
        { id: 4, text: 'Atemnot/Kurzatmigkeit', translation: 25 },
        { id: 5, text: 'Kopfschmerzen', translation: 26 },
        { id: 6, text: 'Gliederschmerzen', translation: 27 },
        { id: 7, text: 'Abgeschlagenheit', translation: 28 },
        {
          id: 8,
          text: 'Verlust des Geruchs- und/oder Geschmackssinns',
          translation: 29,
        },
        { id: 9, text: 'Durchfall', translation: 30 },
      ],
    },
    agreements: [
      {
        translation: 10,
        id: 0,
        text: `Ich habe die allgemeinen Informationen bezüglich der <a href="https://www.barmherzige-hedwig.de/patienten-besucher/wissenswertes-von-a-z/besuchszeiten-und-nachtruhe.html" target="_blank">Besuchsregelungen</a> und der <a href="https://www.barmherzige-hedwig.de/patienten-besucher/wissenswertes-von-a-z/hygieneregeln.html" target="_blank">Hygienemaßnahmen</a> in der Klinik St. Hedwig zur Kenntnis genommen.`,
      },
      {
        translation: 11,
        id: 2,
        text: `Ich bin mit der Speicherung meiner Daten (Name, Telefonnummer, unter der ich regelmäßig zu erreichen bin) für insgesamt 30 Tage aufgrund des Infektionsschutzgesetzes einverstanden.<br /> <v-icon>mdi-check-circle-outline</v-icon>{{$t('forward')}}e Informationen zur Speicherung der Daten finden Sie auf der Seite <a href="/datenschutz.html" target="_blank">Datenschutz</a>.`,
      },
    ],
    form: {
      v: 1,
      q: [],
      s: [],
      a: [],
      f: '',
      l: '',
      n: '',
      d: null,
    },
    invalidNumber: true,
  }),

  methods: {
    generate() {
      this.e6 = 5;
      this.form.d = new Date().toISOString();

      console.log(JSON.stringify(this.form));

      this.$nextTick(() => {
        createCode(JSON.stringify(this.form));
      });

      localStorage.setItem('firstName', this.form.f);
      localStorage.setItem('lastName', this.form.l);
      localStorage.setItem('number', this.form.n);
    },
    handleNumber(value) {
      if (value.valid) {
        this.form.n = value.number.e164;
        this.invalidNumber = false;
      } else {
        this.invalidNumber = true;
      }
    },
    print() {
      window.print();
    },
  },

  mounted() {
    this.form.f = localStorage.getItem('firstName');
    this.form.l = localStorage.getItem('lastName');
    this.number = localStorage.getItem('number');
    this.form.n = this.number;
    if (this.number) {
      this.invalidNumber = false;
    }
  },
};
</script>

<style scoped>
.footer a {
  color: #1976d2;
  text-decoration: none;
}
</style>
