import Vue from 'vue';
import VueRouter from 'vue-router';
import Visitor from '../pages/visitor.vue';
import Student from '../pages/student.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Visitor,
  },
  {
    path: '/studenten/',
    name: 'studenten',
    component: Student,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
