import Vue from 'vue';
import Vuetify, {
  VSnackbar,
  VBtn,
  VIcon,
  VTextField,
  VSelect,
} from 'vuetify/lib';

Vue.use(Vuetify, {
  components: { VSnackbar, VBtn, VIcon, VTextField, VSelect },
});

export default new Vuetify({});
