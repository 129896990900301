<template>
  <div style="width: 260px;" class="ml-3">
    <v-select
      v-model="path"
      :items="pages"
      outlined
      dense
      item-text="name"
      item-value="path"
      @change="redirect"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return {
      pages: [
        { name: 'Besucheranmeldung', path: '/' },
        { name: 'Studentenanmeldung', path: '/studenten/' },
      ],
      path: '',
    };
  },

  methods: {
    redirect() {
      this.$router.push(this.path);
    },
  },
  watch: {},

  created() {
    this.path = this.$route.path;
  },
};
</script>
