<template>
  <div class="locale-changer" style="width: 80px;">
    <v-select v-model="$i18n.locale" :items="langs" outlined dense>
      <template slot="selection" slot-scope="data">
        <div :class="`vti__flag ${data.item}`"></div>
      </template>
      <template slot="item" slot-scope="data">
        <div :class="`vti__flag ${data.item}`"></div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return { langs: ['de', 'us', 'ar', 'bg', 'fr', 'hu', 'ro', 'ru', 'al'] };
  },

  methods: {
    setLocale(locale) {
      console.log('change locale to ', locale);
      this.$i18n.locale = locale;
    },
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(value) {
        if (value === 'ar') {
          this.$vuetify.rtl = true;
        } else {
          this.$vuetify.rtl = false;
        }
      },
    },
  },
};
</script>
